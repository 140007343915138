import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./reducer";
import { NavigateFunction } from "react-router-dom";
import { useSelector, useDispatch, TypedUseSelectorHook } from "react-redux";

import { authAPI } from "./services/AuthService";
import { organizationAPI } from "./services/OrganizationService";
import { brandingAPI } from "./services/BrandingService";
import { phishingAPI } from "./services/PhishingService";
import { domainAPI } from "./services/DomainService";
import { smtpSettingsAPI } from "./services/SmtpProfiles";
import { usersAPI } from "./services/UsersService";
import { changeLogAPI } from "./services/ChangeLogService";
import { coursesAPI } from "./services/course-service";
import { moduleAPI } from "./services/module-service";
import { instructionsAPI } from "./services/InstructionsService";
import { tokenAPI } from "./services/TokenService";
import { globalBaseApi } from "./services/endpoints/base";

export type ExtraArgs = {
  navigate: NavigateFunction;
};

export type SetupStoreParams = {
  extraArgs: ExtraArgs;
} | void;

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware()
        .concat(authAPI.middleware)
        .concat(organizationAPI.middleware)
        .concat(brandingAPI.middleware)
        .concat(phishingAPI.middleware)
        .concat(domainAPI.middleware)
        .concat(smtpSettingsAPI.middleware)
        .concat(usersAPI.middleware)
        .concat(changeLogAPI.middleware)
        .concat(coursesAPI.middleware)
        .concat(moduleAPI.middleware)
        .concat(instructionsAPI.middleware)
        .concat(globalBaseApi.middleware)
        .concat(tokenAPI.middleware);
    },
  });
};

let store: AppStore;

export const setStore = (newStore: AppStore) => {
  store = newStore;
};

export const getStore = () => store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
