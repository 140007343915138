import { lazy } from "react";

import { URLS } from "./urls";

export const routes = [
  {
    needAuth: true,
    path: URLS.ORGANIZATIONS_PAGE,
    element: lazy(
      async () => await import("./../pages/Organizations/Organizations")
    ),
  },
  {
    needAuth: true,
    path: URLS.ORGANIZATION_PAGE,
    element: lazy(
      async () => await import("./../pages/Organization/Organization")
    ),
  },
  {
    needAuth: true,
    path: URLS.ORGANIZATION_EDIT_PAGE,
    element: lazy(
      async () => await import("./../pages/OrganizationEdit/OrganizationEdit")
    ),
  },
  {
    needAuth: true,
    path: URLS.ORGANIZATION_EMPLOYEES_PAGE,
    element: lazy(
      async () =>
        await import("./../pages/OrganizationEmployees/OrganizationEmployees")
    ),
  },
  {
    needAuth: true,
    path: URLS.ORGANIZATION_SUPERUSERS_PAGE,
    element: lazy(
      async () =>
        await import("./../pages/OrganizationSuperUsers/OrganizationSuperUsers")
    ),
  },
  {
    needAuth: true,
    path: URLS.ORGANIZATION_SUPERUSERS_CREATE_PAGE,
    element: lazy(
      async () =>
        await import(
          "./../pages/OrganizationSuperUsersCreate/OrganizationSuperUsersCreate"
        )
    ),
  },
  {
    needAuth: true,
    path: URLS.ORGANIZATION_BRANDING_PAGE,
    element: lazy(
      async () =>
        await import("./../pages/OrganizationBranding/OrganizationBranding")
    ),
  },
  {
    needAuth: true,
    path: URLS.TOKENS_PAGE,
    element: lazy(async () => await import("./../pages/token/tokens")),
  },
  {
    needAuth: true,
    path: URLS.ORGANIZATION_DOMAINS_PAGE,
    element: lazy(async () => await import("./../pages/Domains/Domains")),
  },
  {
    needAuth: true,
    path: URLS.ORGANIZATION_USER_DOMAINS_PAGE,
    element: lazy(
      async () => await import("./../pages/UserDomains/UserDomains")
    ),
  },
  {
    needAuth: true,
    path: URLS.ORGANIZATION_DOMAINS_CREATE_PAGE,
    element: lazy(
      async () => await import("./../pages/DomainsCreate/DomainsCreate")
    ),
  },
  {
    needAuth: true,
    path: URLS.ORGANIZATION_DOMAINS_EDIT_PAGE,
    element: lazy(
      async () => await import("./../pages/DomainsEdit/DomainsEdit")
    ),
  },
  {
    needAuth: true,
    path: URLS.ORGANIZATION_CREATE_PAGE,
    element: lazy(
      async () =>
        await import("./../pages/OrganizationCreate/OrganizationCreate")
    ),
  },
  {
    needAuth: true,
    path: URLS.BRANDING_PAGE,
    element: lazy(async () => await import("./../pages/Branding/Branding")),
  },
  {
    needAuth: true,
    path: `${URLS.TOKEN_PAGE}/:id`,
    element: lazy(async () => await import("./../pages/token")),
  },
  {
    needAuth: true,
    path: `${URLS.TOKEN_CREATE_PAGE}/:id`,
    element: lazy(async () => await import("./../pages/token/create")),
  },
  {
    needAuth: true,
    path: URLS.BRANDING_CREATE_PAGE,
    element: lazy(
      async () => await import("./../pages/BrandingCreate/BrandingCreate")
    ),
  },
  {
    needAuth: true,
    path: URLS.BRANDING_EDIT_PAGE,
    element: lazy(
      async () => await import("./../pages/BrandingEdit/BrandingEdit")
    ),
  },
  {
    needAuth: true,
    path: URLS.DOMAINS_PAGE,
    element: lazy(async () => await import("./../pages/Domains/Domains")),
  },
  {
    needAuth: true,
    path: URLS.DOMAINS_CREATE_PAGE,
    element: lazy(
      async () => await import("./../pages/DomainsCreate/DomainsCreate")
    ),
  },
  {
    needAuth: true,
    path: URLS.DOMAINS_EDIT_PAGE,
    element: lazy(
      async () => await import("./../pages/DomainsEdit/DomainsEdit")
    ),
  },
  {
    needAuth: true,
    path: URLS.PHISHING_TEMPLATES,
    permission: "admin.phishing.templates",
    element: lazy(async () => await import("./../pages/PhishingTemplates")),
  },
  {
    needAuth: true,
    permission: "admin.phishing.templates",
    path: URLS.PHISHING_TEMPLATE,
    element: lazy(async () => await import("./../pages/PhishingTemplate")),
  },
  {
    needAuth: true,
    permission: "admin.phishing.templates",
    path: URLS.PHISHING_TEMPLATE_EDIT,
    element: lazy(async () => await import("./../pages/PhishingTemplatesEdit")),
  },
  {
    needAuth: true,
    permission: "admin.phishing.templates",
    path: URLS.PHISHING_TEMPLATE_PAGE_EDIT,
    element: lazy(
      async () => await import("./../pages/TemplateEmailPageLandingForm")
    ),
  },
  {
    needAuth: true,
    permission: "admin.phishing.templates",
    path: URLS.PHISHING_TEMPLATE_PAGE_CREATE,
    element: lazy(
      async () => await import("./../pages/TemplateEmailPageLandingForm")
    ),
  },
  {
    needAuth: true,
    permission: "admin.phishing.templates",
    path: URLS.PHISHING_TEMPLATES_EMAIL_CREATE,
    element: lazy(
      async () => await import("./../pages/PhishingTemplateEmailForm")
    ),
  },
  {
    needAuth: true,
    permission: "admin.phishing.templates",
    path: URLS.PHISHING_TEMPLATES_EMAIL_EDIT,
    element: lazy(
      async () => await import("./../pages/PhishingTemplateEmailForm")
    ),
  },
  {
    needAuth: true,
    permission: "admin.phishing.templates",
    path: URLS.PHISHING_TEMPLATES_NEW,
    element: lazy(
      async () => await import("./../pages/PhishingTemplatesCreate")
    ),
  },
  {
    needAuth: true,
    path: URLS.SMTP_PROFILES_PAGE,
    element: lazy(
      async () => await import("./../pages/SmtpProfiles/SmtpProfiles")
    ),
  },
  {
    needAuth: true,
    path: URLS.SMTP_PROFILES_CREATE_PAGE,
    element: lazy(
      async () =>
        await import("./../pages/SmtpProfilesCreate/SmtpProfilesCreate")
    ),
  },
  {
    needAuth: true,
    path: URLS.SMTP_PROFILES_EDIT_PAGE,
    element: lazy(
      async () => await import("./../pages/SmtpProfilesEdit/SmtpProfilesEdit")
    ),
  },
  {
    needAuth: true,
    path: URLS.PHISHINGS_PAGE,
    element: lazy(async () => await import("./../pages/Phishings/Phishings")),
  },
  {
    needAuth: true,
    path: URLS.PHISHING_PAGE,
    element: lazy(async () => await import("./../pages/Phishing/Phishing")),
  },
  {
    needAuth: false,
    path: URLS.LOGIN_PAGE,
    element: lazy(async () => await import("./../pages/Login/Login")),
  },
  {
    needAuth: true,
    path: URLS.INSTRUCTION_PAGE,
    element: lazy(async () => await import("./../pages/Instruction")),
  },
  {
    needAuth: true,
    path: URLS.INSTRUCTION_CREATE_PAGE,
    element: lazy(async () => await import("./../pages/Instruction/Create")),
  },
  {
    needAuth: true,
    path: URLS.INSTRUCTION_PAGE,
    element: lazy(async () => await import("./../pages/Instruction")),
  },
  {
    needAuth: true,
    path: URLS.CHANGE_LOG_PAGE,
    element: lazy(async () => await import("./../pages/ChangeLog")),
  },
  {
    needAuth: true,
    path: URLS.CHANGE_LOG_CREATE_PAGE,
    element: lazy(async () => await import("./../pages/ChangeLog/Create")),
  },
  {
    needAuth: true,
    path: `${URLS.CHANGE_LOG_EDIT_PAGE}/:id`,
    element: lazy(async () => await import("./../pages/ChangeLog/Edit")),
  },
  {
    needAuth: true,
    path: `${URLS.CHANGE_LOG_PAGE}/:id`,
    element: lazy(async () => await import("./../pages/ChangeLog/Detail")),
  },
  {
    needAuth: true,
    path: `${URLS.COURSES_PAGE}`,
    element: lazy(async () => await import("./../pages/Courses")),
  },
  {
    needAuth: true,
    path: `${URLS.LEARNING_THEMES_PAGE}`,
    element: lazy(async () => await import("../pages/Themes")),
  },
  {
    needAuth: false,
    path: "*",
    element: lazy(async () => await import("./../pages/Login/Login")),
  },
  {
    needAuth: true,
    path: "*",
    element: lazy(
      async () => await import("./../pages/Organizations/Organizations")
    ),
  },
];
