import { createApi } from "@reduxjs/toolkit/query/react";
import { IOrganization } from "../../types/IOrganization";
import { baseQueryWithReauth } from "../helper";
import { Token, TokenWithOrgTitle } from "../../types/IToken";

export const tokenAPI = createApi({
  reducerPath: "tokenAPI",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["api-tokens"],
  endpoints: (build) => ({
    getTokens: build.query<Token[], { id: string }>({
      query: ({ id }) => ({
        url: `/admin/organizations/${id}/public-api-tokens`,
      }),
      providesTags: () => ["api-tokens"],
    }),
    getAllTokens: build.query<TokenWithOrgTitle[], void>({
      query: () => ({
        url: `/admin/organizations/public-api-tokens/all`,
      }),
      providesTags: () => ["api-tokens"],
    }),
    createToken: build.mutation<
      IOrganization[],
      { organization_id: string; life_days: number }
    >({
      query: (body) => ({
        url: `/admin/organizations/public-api-token`,
        body,
        method: "POST",
      }),
      invalidatesTags: (result) => ["api-tokens"],
    }),
    deleteToken: build.mutation<
      void,
      { organization_id: string; token_id: string }
    >({
      query: ({ organization_id, token_id }) => ({
        url: `/admin/organizations/${organization_id}/public-api-tokens/${token_id}`,
        method: "DELETE",
      }),
      invalidatesTags: () => ["api-tokens"],
    }),
  }),
});

export const {
  useGetTokensQuery,
  useCreateTokenMutation,
  useDeleteTokenMutation,
  useGetAllTokensQuery,
} = tokenAPI;
