import { createApi, EndpointBuilder } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../../helper";

export type GlobalTagTypes =
  | "themes"
  | "assigned-course"
  | "assigned-courses"
  | "courses"
  | "theme"
  | "quiz"
  | "slide"
  | "video"
  | "presentation"
  | "article"
  | "scorm"
  | "steps"
  | "gallery"
  | "courses-by-tag"
  | "email-template"
  | "email-templates"
  | "default-emails-templates"
  | "course";

const tagTypes: GlobalTagTypes[] = [
  "themes",
  "assigned-course",
  "courses",
  "theme",
  "quiz",
  "slide",
  "video",
  "presentation",
  "article",
  "scorm",
  "steps",
  "gallery",
  "courses-by-tag",
  "email-template",
  "email-templates",
  "default-emails-templates",
  "course",
];

export type GlobalEndpointBuilder = EndpointBuilder<
  typeof baseQueryWithReauth,
  GlobalTagTypes,
  typeof globalBaseApi.reducerPath
>;

export const globalBaseApi = createApi({
  baseQuery: (args, api, extraOptions) =>
    baseQueryWithReauth(
      { ...args, baseUrl: process.env.REACT_APP_API_GLOBAL },
      api,
      extraOptions
    ),
  tagTypes,
  endpoints: () => ({}),
  reducerPath: "globalBaseApi",
});

export type GlobalBaseApi = typeof globalBaseApi;
