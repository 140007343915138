import { combineSlices } from "@reduxjs/toolkit";
import { coursesAPI } from "./services/course-service";
import { moduleAPI } from "./services/module-service";
import { authAPI } from "./services/AuthService";
import { organizationAPI } from "./services/OrganizationService";
import { brandingAPI } from "./services/BrandingService";
import { domainAPI } from "./services/DomainService";
import { smtpSettingsAPI } from "./services/SmtpProfiles";
import { phishingAPI } from "./services/PhishingService";
import { usersAPI } from "./services/UsersService";
import { instructionsAPI } from "./services/InstructionsService";
import { changeLogAPI } from "./services/ChangeLogService";
import { tokenAPI } from "./services/TokenService";
import authSlice from "./auth-slice";
import { globalBaseApi } from "./services/endpoints/base";

export interface LazyLoadedSlices {}

export const rootReducer = combineSlices(
  authAPI,
  organizationAPI,
  brandingAPI,
  domainAPI,
  smtpSettingsAPI,
  phishingAPI,
  usersAPI,
  instructionsAPI,
  changeLogAPI,
  coursesAPI,
  moduleAPI,
  tokenAPI,
  globalBaseApi,
  { auth: authSlice }
).withLazyLoadedSlices<LazyLoadedSlices>();
