import { createApi } from "@reduxjs/toolkit/query/react";
import { UUID } from "../../types/IOrganization";
import { baseQueryWithReauth } from "../helper";
import { ISmtpProfile, ISmtpProfileBody } from "@/src/types/ISmtpProfile";

export const smtpSettingsAPI = createApi({
  reducerPath: "smtpSettingsAPI",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["smtp_settings"],
  endpoints: (build) => ({
    getSmtpProfiles: build.query<ISmtpProfile[], void>({
      query: () => ({
        url: '/admin/smtp_settings',
      }),
      transformResponse: (response: { data: ISmtpProfile[] }, meta, arg) =>
        response.data,
      providesTags: (result) => ["smtp_settings"],
    }),
    editSmtpSettings: build.mutation<ISmtpProfile, { id: UUID; body: ISmtpProfileBody }>({
      query: ({ id, body }) => ({
        url: `/admin/smtp_settings/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["smtp_settings"],
    }),
    createSmtpSetting: build.mutation<ISmtpProfile, ISmtpProfileBody>({
      query: (body) => ({
        url: `/admin/smtp_settings`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["smtp_settings"],
    }),
    deleteSmtpSetting: build.mutation<void, UUID>({
      query: (id) => ({
        url: `/admin/smtp_settings/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["smtp_settings"],
    }),
  }),
});

export const {
    useGetSmtpProfilesQuery,
    useCreateSmtpSettingMutation,
    useEditSmtpSettingsMutation,
    useDeleteSmtpSettingMutation
} = smtpSettingsAPI;
