import { createApi } from "@reduxjs/toolkit/query/react";
import {
  ChangeLogDetailItem,
  ChangeLogItem,
  ChangeLogsResponse,
} from "../../types/IChangeLog";
import { baseQueryWithReauth } from "../helper";

export const changeLogAPI = createApi({
  reducerPath: "changeLogAPI",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["changelogs"],
  endpoints: (build) => ({
    getChangeLogs: build.query<ChangeLogsResponse, void>({
      query: () => ({
        url: "organizations/changelogs",
        method: "GET",
      }),
      providesTags: ["changelogs"],
    }),

    deleteChangeByIdLog: build.mutation<void, string>({
      query: (id) => ({
        url: `admin/changelogs/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["changelogs"],
    }),
    updateChangeLog: build.mutation<
      ChangeLogItem,
      { id: string; body: Omit<ChangeLogItem, "id" | "created_at"> }
    >({
      query: ({ body, id }) => ({
        url: "admin/changelogs/" + id,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["changelogs"],
    }),
    getChangeLog: build.query<ChangeLogDetailItem, string>({
      query: (id) => ({
        url: "organizations/changelogs/" + id,
        method: "GET",
      }),
      providesTags: ["changelogs"],
    }),
    createChangeLog: build.mutation<void, Omit<ChangeLogDetailItem, "id">>({
      query: (body) => ({
        url: "admin/changelogs",
        method: "POST",
        body,
      }),
      invalidatesTags: ["changelogs"],
    }),
  }),
});

export const {
  useGetChangeLogsQuery,
  useGetChangeLogQuery,
  useUpdateChangeLogMutation,
  useCreateChangeLogMutation,
  useDeleteChangeByIdLogMutation,
} = changeLogAPI;
