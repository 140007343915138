import {
  fetchBaseQuery,
  FetchArgs,
  BaseQueryApi,
} from "@reduxjs/toolkit/query";

import { logIn, logOut } from "./auth-slice";
import { API_DOMAIN } from "../config";

const baseQuery = (overrideBaseUrl?: string) =>
  fetchBaseQuery({
    baseUrl: overrideBaseUrl ?? API_DOMAIN,
    credentials: "include",
    headers: {
      "X-Referer": window.location.href,
    },
  });

export const baseQueryWithReauth = async (
  args: FetchArgs & {
    baseUrl?: string;
  },
  api: BaseQueryApi,
  extraOptions: any
) => {
  let result = await baseQuery(args.baseUrl)(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshResult = await baseQuery(args.baseUrl)(
      "auth/oauth2/refresh",
      api,
      extraOptions
    );

    if (refreshResult) {
      api.dispatch(logIn());
      result = await baseQuery(args.baseUrl)(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};
