import { createApi } from "@reduxjs/toolkit/query/react";
import { IDomain, UUID } from "../../types/IOrganization";
import { baseQueryWithReauth } from "../helper";

export const brandingAPI = createApi({
  reducerPath: "brandingAPI",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["domains"],
  endpoints: (build) => ({
    getBranding: build.query<IDomain[], void>({
      query: () => ({
        url: `/admin/domains`,
      }),
      transformResponse: (response: { data: any[] }, meta, arg) =>
        response.data,
      providesTags: (result) => ["domains"],
    }),
    editBranding: build.mutation<void, { id: UUID; body: FormData }>({
      query: ({ id, body }) => ({
        url: `/admin/domains/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["domains"],
    }),
    editOrganizationBranding: build.mutation<
      void,
      { id: UUID; body: FormData }
    >({
      query: ({ id, body }) => ({
        url: `/admin/domains/organization/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["domains"],
    }),
    createBranding: build.mutation<IDomain, FormData>({
      query: (body) => ({
        url: `/admin/domains`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["domains"],
    }),
  }),
});

export const {
  useGetBrandingQuery,
  useEditBrandingMutation,
  useCreateBrandingMutation,
  useEditOrganizationBrandingMutation,
} = brandingAPI;
