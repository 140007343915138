import { createApi } from "@reduxjs/toolkit/query/react";
import { UUID } from "../../types/IOrganization";
import { IUser, IUserCreateResponse } from "../../types/IUsers";
import { baseQueryWithReauth } from "../helper";

export const usersAPI = createApi({
  reducerPath: "usersAPI",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["superusers"],
  endpoints: (build) => ({
    getSuperusers: build.query<IUser[], UUID>({
      query: (id) => ({
        url: `/admin/hidden-super-user/organization/${id}`,
      }),
      transformResponse: (response: { data: any[] }, meta, arg) =>
        response.data,
      providesTags: (result) => ["superusers"],
    }),
    deleteSuperuser: build.mutation<void, UUID>({
      query: (id) => ({
        url: `/admin/hidden-super-user/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["superusers"],
    }),
    createSuperuser: build.mutation<IUserCreateResponse, UUID>({
      query: (organization_id) => ({
        url: `/admin/hidden-super-user`,
        method: "POST",
        body: { organization_id },
      }),
      invalidatesTags: ["superusers"],
    }),
  }),
});

export const {
  useGetSuperusersQuery,
  useDeleteSuperuserMutation,
  useCreateSuperuserMutation,
} = usersAPI;
