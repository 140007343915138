import { TIcons } from "../components";
import { URLS } from "./urls";

interface IRoute {
  title: string;
  route: string;
  icon?: TIcons;
  pages?: IRoute[];
}

export const menu: IRoute[] = [
  {
    title: "Организации",
    route: URLS.ORGANIZATIONS_PAGE,
    icon: "organizations",
  },
  {
    title: "Брендинг",
    route: URLS.BRANDING_PAGE,
    icon: "learn3",
  },
  {
    title: "Домены",
    route: URLS.DOMAINS_PAGE,
    icon: "learn2",
  },
  {
    title: "SMTP профили",
    route: URLS.SMTP_PROFILES_PAGE,
    icon: "smtp",
  },
  {
    title: "Курсы",
    route: URLS.COURSES_PAGE,
    icon: "learn1",
  },
  {
    title: "Темы",
    route: URLS.LEARNING_THEMES_PAGE,
    icon: "modules",
  },
  {
    title: "Шаблоны",
    route: URLS.PHISHING_TEMPLATES,
    icon: "fishingmessage",
  },
  {
    title: "Рассылки",
    route: URLS.PHISHINGS_PAGE,
    icon: "mailing",
  },
  {
    title: "Инструкция",
    route: URLS.INSTRUCTION_PAGE,
    icon: "upload",
  },
  {
    title: "API Токены",
    route: URLS.TOKENS_PAGE,
    icon: "key",
  },
  {
    title: "Журнал изменений",
    route: URLS.CHANGE_LOG_PAGE,
    icon: "calendar",
  },
];
