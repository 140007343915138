import { createApi } from "@reduxjs/toolkit/query/react";
import { UUID } from "../../types/IOrganization";
import { IPhishing, IPhishingQuery } from "../../types/IPhishing";
import { baseQueryWithReauth } from "../helper";
import { IPhishingCategoriesReponse, IPhishingStatisticsChart, IPhishingTemplate, IPhishingTemplateWithData } from "@/src/pages/PhishingTemplates";
import { IPhishingCreateTemplatesRequest } from "@/src/pages/PhishingTemplatesCreate/PhishingTemplatesCreate.d";
import { IEmail } from "@/src/pages/PhishingTemplate";

export const phishingAPI = createApi({
  reducerPath: "phishingAPI",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["phishing", "templates", "template-by-id", "emails", "email", "page-by-id"],
  endpoints: (build) => ({
    getTemplates: build.query<IPhishingTemplate[], { search?: string }>({
      query: ({ search }) => ({
        url: `/admin/phish/templates${search ? `?search=${search}&` : ''}`,
      }),
      providesTags: ['templates'],
      transformResponse: (response: IPhishingTemplateWithData) => response.data,
    }),
    copyTemplateById: build.mutation<ResponseWithNotification<IPhishingTemplate>, { id: string }>({
      query: ({ id }) => ({
        url: `/admin/phish/templates/${id}/copy`,
        method: 'POST',
      }),
      invalidatesTags: result => {
        return result?.id ? ['templates'] : []
      },
    }),
    createTemplate: build.mutation<ResponseWithNotification<IPhishingTemplate>, FormData>({
      query: body => ({
        url: `/admin/phish/templates`,
        method: 'POST',
        formData: true,
        body,
      }),
      invalidatesTags: result => {
        return result?.id ? ['templates'] : []
      },
    }),
    getCategories: build.query<IPhishingCategoriesReponse, void>({
      query: () => ({
        url: `/admin/phish/categories`,
        method: 'GET',
      }),
    }),
    getPhishing: build.query<IPhishing[], IPhishingQuery>({
      query: ({ search, status, organizationID }) => ({
        url: `/admin/phishing_campaigns${`?search=${search || ""}`}${
          status ? `&status=${status}` : ""
        }${organizationID ? `&organization_id=${organizationID}` : ""}&`,
      }),
      transformResponse: (response: { data: any[] }, meta, arg) =>
        response.data,
      providesTags: (result) => ["phishing"],
    }),
    getTemplateById: build.query<IPhishingTemplate, UUID | null>({
      query: id => ({
        url: `/admin/phish/templates/${id}`,
      }),
      providesTags: ['template-by-id'],
    }),
    patchPhishingTemplateById: build.mutation<
      ResponseWithNotification<IPhishingTemplate>,
      { body: FormData; id: string }
    >({
      query: ({ body, id }) => ({
        url: `/admin/phish/templates/` + id,
        method: 'PATCH',
        formData: true,
        body,
      }),
      invalidatesTags: ['template-by-id', 'templates'],
    }),
    restartPhishing: build.mutation<void, UUID>({
      query: (id) => ({
        url: `/admin/phishing_campaigns/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["phishing"],
    }),
    deleteEmails: build.mutation<ResponseWithNotification<void>, UUID | null>({
      query: id => ({
        url: `/phishing/emails/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['emails'],
    }),
    deletePhishingTemplate: build.mutation<ResponseWithNotification<void>, UUID | null>({
      query: id => ({
        url: `/admin/phish/templates/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['templates'],
    }),
    getEmail: build.query<IEmail, UUID | null>({
      query: id => ({
        url: `/phishing/emails/${id}`,
      }),
      providesTags: ['email'],
    }),
    getPageById: build.query<IEmail, UUID | null>({
      query: id => ({
        url: `/phishing/pages/${id}`,
      }),
      providesTags: ['page-by-id'],
    }),
    getTemplateStatistic: build.query<IPhishingStatisticsChart, UUID | null>({
      query: id => ({
        url: `/phishing/templates/${id}/statistics`,
      }),
    }),
    createPageById: build.mutation<IEmail, Pick<IEmail, 'html'> & { template_id: string }>({
      query: body => ({
        url: `/phishing/pages`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['page-by-id'],
    }),
    patchPageById: build.mutation<IEmail, IEmail>({
      query: body => ({
        url: `/phishing/pages/${body.id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['page-by-id'],
    }),
    createEmail: build.mutation<ResponseWithNotification<IEmail>, Omit<IEmail, 'id'>>({
      query: body => ({
        url: `/phishing/emails`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['email', 'template-by-id'],
    }),
    patchEmail: build.mutation<ResponseWithNotification<IEmail>, IEmail>({
      query: body => ({
        url: `/phishing/emails/${body.id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['email', 'template-by-id'],
    }),
  }),
});

export const { 
  useGetPhishingQuery,
  useRestartPhishingMutation, 
  useGetTemplatesQuery, 
  useCopyTemplateByIdMutation, 
  useCreateTemplateMutation, 
  useGetCategoriesQuery,
  useGetTemplateByIdQuery,
  usePatchPhishingTemplateByIdMutation,
  useDeleteEmailsMutation,
  useDeletePhishingTemplateMutation,
  useGetEmailQuery,
  useGetPageByIdQuery,
  useGetTemplateStatisticQuery,
  useCreatePageByIdMutation,
  usePatchPageByIdMutation,
  useCreateEmailMutation,
  usePatchEmailMutation
} = phishingAPI;
