import { createApi } from "@reduxjs/toolkit/query/react";
import { ICreateDomainBody, IDomain, IUserDomains } from "../../types/IDomain";
import { UUID } from "../../types/IOrganization";
import { baseQueryWithReauth } from "../helper";

export const domainAPI = createApi({
  reducerPath: "domainAPI",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["domain"],
  endpoints: (build) => ({
    getDomains: build.query<IDomain[], UUID | void>({
      query: (id) => ({
        url: `/phishing/domains/v01${id ? `?organization_id=${id}&` : ""}`,
      }),
      transformResponse: (response: { domains: any[] }, meta, arg) =>
        response.domains,
      providesTags: (result) => ["domain"],
    }),
    getUserDomains: build.query<IUserDomains, UUID | void>({
      query: (id) => ({
        url: `/admin/organizations/${id}/email-domains`,
      }),
      providesTags: (result) => ["domain"],
    }),
    editUserDomains: build.mutation<IDomain, { id: UUID; body: IUserDomains }>({
      query: ({ id, body }) => ({
        url: `/admin/organizations/${id}/email-domains`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["domain"],
    }),
    createDomain: build.mutation<IDomain, ICreateDomainBody>({
      query: (body) => ({
        url: `/phishing/domains`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["domain"],
    }),
    editDomain: build.mutation<IDomain, { id: UUID; body: ICreateDomainBody }>({
      query: ({ id, body }) => ({
        url: `/phishing/domains/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["domain"],
    }),
    deleteDomain: build.mutation<void, UUID>({
      query: (id) => ({
        url: `/phishing/domains/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["domain"],
    }),
  }),
});

export const {
  useGetDomainsQuery,
  useGetUserDomainsQuery,
  useCreateDomainMutation,
  useEditDomainMutation,
  useEditUserDomainsMutation,
  useDeleteDomainMutation,
} = domainAPI;
