import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../helper";
import {
  InstructionCreateResponse,
  InstructionsResponse,
} from "../../types/IInstructions";

export const instructionsAPI = createApi({
  reducerPath: "InstructionsAPI",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["instructions"],
  endpoints: (build) => ({
    getInstructions: build.query<InstructionsResponse, void>({
      query: () => ({
        url: "organizations/instructions",
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }),
      providesTags: ["instructions"],
    }),
    uploadInstructions: build.mutation<InstructionCreateResponse, FormData>({
      query: (body) => ({
        url: "admin/instructions",
        method: "POST",
        formData: true,
        body,
      }),
      invalidatesTags: ["instructions"],
    }),
    deleteByIdInstructions: build.mutation<void, string>({
      query: (id) => ({
        url: `admin/instructions/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["instructions"],
    }),
  }),
});

export const {
  useGetInstructionsQuery,
  useUploadInstructionsMutation,
  useDeleteByIdInstructionsMutation,
} = instructionsAPI;
